import { useState } from 'react';
import { DatatableWrapper, Filter, Pagination, TableBody, TableHeader } from 'react-bs-datatable';
import { Col, Row, Table, Button } from 'react-bootstrap';
import { SendNotifications } from './SendNotifications';
import { AddCouponsToAll } from './AddCouponsToAll';
import { AddBonuses } from './AddBonuses';

export const DataTable = ({ body, headers, filters, brands }) => {
	const [messageState, setMessageState] = useState(false);
	const [couponState, setCouponState] = useState(false);
	const [AddBonusState, setAddBonusState] = useState(false);

	return (
		<div
			className=""
			style={{ overflowX: 'auto' }}>
			<DatatableWrapper
				body={body}
				headers={headers}
				paginationOptionsProps={{
					initialState: {
						rowsPerPage: 15,
					},
				}}>
				<Row className="pb-3 d-flex flex-col justify-content-between g-0">
					<Col className="col-3">{body.length} records found</Col>
					<Col className="col-3">
						<Filter classes={{ clearButton: 'btn-dark btn-sm', input: 'form-control-sm' }} />
					</Col>
				</Row>
				<Table>
					<TableHeader />
					<TableBody />
				</Table>
				<Row className="pt-3 g-0">
					<Col className="d-flex flex-col justify-content-between">
						<div className="button-container">
							<Button
								type="button"
								onClick={() => {
									setMessageState(true);
								}}
								className="me-2">
								Send New Notifications
							</Button>
							<SendNotifications
								state={messageState}
								changeState={setMessageState}
								filters={filters}
							/>
							<Button
								type="button"
								onClick={() => {
									setCouponState(true);
								}}
								className="me-2 btn-warning">
								Add Coupons
							</Button>
							<AddCouponsToAll
								state={couponState}
								changeState={setCouponState}
								filters={filters}
							/>
							<Button
								type="button"
								onClick={() => {
									setAddBonusState(true);
								}}
								className="me-2 btn-warning">
								Add Bonuses
							</Button>
							<AddBonuses
								state={AddBonusState}
								changeState={setAddBonusState}
								filters={filters}
								brands={brands}
							/>
						</div>
						<Pagination classes={{ button: 'btn-dark btn-sm' }} />
					</Col>
				</Row>
			</DatatableWrapper>
		</div>
	);
};
